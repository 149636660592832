<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            :style="{
                backgroundColor: disableSave ? '#DDD8D8' : '#0ABAB5',
                color: disableSave ? '#888' : '#ffffff'
            }"
            :disabled="disableSave"
            @click="saveData"
        >Save</button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >Cancel</button>
    </section>

    <section class="uk-margin">
        <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
            v-for="(item, oKey) in main"
            :key="oKey"
        >
            <div class="uk-width-1-2 uk-margin-auto-vertical">{{ item.label }}</div>
            <div class="uk-width-1-2">
                <div class="uk-grid-collapse" uk-grid>
                    <!-- Multi select input -->
                    <div v-if="item.type === 'select'" class="uk-width-expand">
                        <select v-model="form[item.value]" class="uk-select">
                            <option v-for="option in item.options" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>

                    <!-- Number input -->
                    <div v-else-if="item.type === 'cols'" class="uk-flex uk-flex-middle uk-width-1-1" uk-grid>
                        <div class="uk-inline uk-width-expand@m">
                            <span class="uk-form-icon uk-form-icon-flip uk-text-muted" style="background-color: #E5E5E5; color: #343434;">IDR</span>
                            <input
                                v-model="form[item.value]"
                                class="uk-input"
                                :class="{'uk-form-danger': errors.has(item.value)}"
                                type="text"
                                :name="item.value"
                                v-validate="'required'"
                            />
                        </div>
                        <div v-if="item.value == 'fee_overtime_hourly'" class="uk-width-auto@m">Per Hour</div>
                    </div>
                    <div v-else class="uk-inline uk-width-expand">
                        <span class="uk-form-icon uk-form-icon-flip uk-text-muted" style="background-color: #E5E5E5; color: #343434;">IDR</span>
                        <input
                            v-model="form[item.value]"
                            class="uk-input"
                            type="text"
                            :name="item.value"
                        />
                    </div>
                </div>
                <div v-if="errorsD[item.value]" class="uk-text-danger">{{ errorsD[item.value] }}</div>
            </div>
        </div>

        <hr>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDangerCustom,
} from "@/utils/notification";

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
            main: [
                { label: 'Salary Amount', value: 'fee_normal' },
                {
                    label: 'Salary Unit',
                    value: 'fee_normal_unit',
                    type: "select",
                    options: [
                        { value: "Month", text: "Monthly" },
                        { value: "Day", text: "Daily" },
                        { value: "Hour", text: "Hourly" },
                        { value: "Packet", text: "Packet" },
                    ]
                },
                { label: 'Overtime Fee', value: 'fee_overtime_hourly', type: "cols" },
            ],
            form: { ...this.$props.data},
            errorsD: {},
            disableSave: false,
        };
    },
    watch: {
        'form.fee_normal': {
            handler: function(value) {
                if (parseInt(value) < 1) {
                    this.disableSave = true;
                    notificationDangerCustom("Mitra fee must not 0!");
                } else {
                    this.disableSave = false;
                }
                this.formatText(value, 'fee_normal');
            },
            immediate: true
        },
        'form.fee_overtime_hourly': {
            handler: function(value) {
                this.formatText(value, 'fee_overtime_hourly');
            },
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            patchSalary: 'mitra/patchSalary',
        }),
        async saveData() {
            this.isLoading = true;

            if (!this.form.fee_normal) {
                notificationDangerCustom("Mitra fee must not 0!");
            } else {
                const payload = {
                    fee_normal: this.form.fee_normal.replace(/[^0-9]/g, ''),
                    fee_normal_unit: this.form.fee_normal_unit,
                    fee_overtime_hourly: this.form.fee_overtime_hourly.replace(/[^0-9]/g, '')
                };

                const res = await this.patchSalary({ user_id: this.$route.params.mitra_id, payload });
                if (res.status == 'OK') {
                    notificationSuccess('Salary updated!');
                } else {
                    notificationDangerCustom("Failed to update mitra salary!");
                }
            }

            this.isLoading = false;
            this.$emit('action', false);
        },
        formatText(value, formName) {
            const balance = value.toString().replace(/[^0-9]/g, '');
            this.form[formName] = formatter.thousandSeparator(balance);
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
