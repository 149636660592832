<template>
    <div>
        <section>
            <button
                v-if="$can('EDIT', 'worker-mitra')"
                class="uk-button uk-button-default uk-border-rounded uk-margin-right"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
                @click="$emit('action', true)"
            >Edit</button>
        </section>
        <section class="uk-margin">
            <div v-for="(item, oKey) in main" :key="oKey">
                <div
                    class="uk-grid-collapse"
                    style="padding: .2rem 0"
                    uk-grid
                >
                    <div class="uk-width-1-2">{{ item.label }}</div>
                    <div class="uk-width-1-2">
                        <div class="uk-grid-collapse" uk-grid>
                            <div class="uk-width-auto">:&nbsp;</div>
                            <div v-if="item.value == 'fee_normal_unit'" class="uk-width-expand">
                                {{ data[item.value] ? convertFeeFormat(data[item.value]) : '-' }}
                            </div>
                            <div v-else class="uk-width-expand">
                                {{ data[item.value] ? formatText(data[item.value]) : 0 }} IDR
                                <span v-if="item.value == 'fee_overtime_hourly'">{{ ' Per Hour' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <!-- <h5 class="uk-text-muted">Manfaat Perusahaan</h5>
            <section>
                <div
                    class="uk-grid-collapse"
                    style="padding: .2rem 0"
                    uk-grid
                    v-for="(benefit, oKey) in companyBenefits"
                    :key="oKey"
                >
                    <div class="uk-width-1-2">{{ benefit.label }}</div>
                    <div class="uk-width-1-2">
                        <div class="uk-grid-collapse" uk-grid>
                            <div class="uk-width-auto">:&nbsp;</div>
                            <div class="uk-width-expand">
                                {{ 'IDR' }}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr>

            <h5 class="uk-text-muted">Manfaat Individu</h5>
            <section>
                <div
                    class="uk-grid-collapse"
                    style="padding: .2rem 0"
                    uk-grid
                    v-for="(benefit, oKey) in userBenefits"
                    :key="oKey"
                >
                    <div class="uk-width-1-2">{{ benefit.label }}</div>
                    <div class="uk-width-1-2">
                        <div class="uk-grid-collapse" uk-grid>
                            <div class="uk-width-auto">:&nbsp;</div>
                            <div class="uk-width-expand">
                                {{ 'IDR' }}
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            isLoading: false,
            main: [
                { label: 'Salary Amount', value: 'fee_normal' },
                { label: 'Salary Unit', value: 'fee_normal_unit' },
                { label: 'Overtime Fee', value: 'fee_overtime_hourly' },
            ],
            companyBenefits: [
                [
                    { label: "Salary Type", value: "" },
                    { label: "BPJS Kes Per", value: "" },
                    { label: "BPJS TK JHT Per", value: "" },
                    { label: "BPJS TK JKK Per", value: "" },
                    { label: "BPJS TK JK Per", value: "" },
                    { label: "BPJS TK JP Per", value: "" },
                    { label: "Minimum Work Duration", value: "" },
                ]
            ],
            userBenefits: [
                [
                    { label: "BPJS Kes Kar", value: "" },
                    { label: "BPJS TK JHT Kar", value: "" },
                    { label: "BPJS TK JKK Kar", value: "" },
                    { label: "BPJS TK JK Kar", value: "" },
                    { label: "BPJS TK JP Kar", value: "" },
                    { label: "Minimum Work Duration", value: "" },
                ]
            ],
            data: null,
        };
    },
    async mounted() {
        this.getUserSalaryData();
    },
    methods: {
        ...mapActions({
            getSalaryData: 'mitra/getSalaryData',
        }),
        async getUserSalaryData() {
            this.isLoading = true;
            const data = await this.getSalaryData(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result;
                this.$emit("passData", this.data); 
            }

            this.isLoading = false;
        },
        convertFeeFormat(unit) {
            switch (unit) {
            case 'Hour':
            case 'Jam':
                return 'Hourly';
            case 'Day':
            case 'Hari':
                return 'Daily';
            case 'Month':
            case 'Bulan':
                return 'Monthly';
            case 'Packet':
            case 'Paket':
                return 'Packet';
            }
        },
        formatText(value) {
            return formatter.thousandSeparator(value);
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
